import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerProvider } from '../providers/explorer-provider';
import { ExplorerHomeBannerLayout } from './explorer-home-banner-layout';
import { ExplorerEntityDetailsLayout } from '@shapeable/ui';
import { ExplorerHomeViewLayout } from './explorer-home-view-layout';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
const cls = classNames('radar-home-layout');

// -------- Types -------->

export type ExplorerHomeLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerHomeLayoutDefaultProps: Omit<ExplorerHomeLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${theme.COLOR('bright')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`

  `,
  desktop: css`
    
  `,
});

const NavigatorStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
    Navigator: styled(ValueChainNavigator)`${NavigatorStyles}`,
};

export const ExplorerHomeLayout: Shapeable.FC<ExplorerHomeLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isShown } = useValueChainMap();
  
  return (
    <ExplorerProvider>

    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={800}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        entity={entity}
        isAsideSticky
        stickyTopOffset={117}
        asideIsRevealed={isShown}
        
        banner={
          <ExplorerHomeBannerLayout entity={entity} />
        }
        content={
          <ExplorerHomeViewLayout entity={entity} />
        }
        dataVizMain={
          <ValueChainNavigatorMain />
        }

        // aside
        dataViz={
          <My.Navigator />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerHomeLayout.defaultProps = ExplorerHomeLayoutDefaultProps;
ExplorerHomeLayout.cls = cls;