module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HE48YGT0JN"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"810bd738127dd4133261b486f88f32ab"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"includeProfile":true,"fonts":["Montserrat:300,300i,500,500i,700","IBM+Plex+Serif:300,300i,400,400i,500,500i,600,600i,700","IBM+Plex+Sans:300,300i,400,400i,500,500i,600,600i,700","Poppins:300,300i,400,400i,500,500i,600,600i,700","IBM+Plex+Sans+Condensed:300,400,500,600","Lustria:400"],"redirects":{"https://copperconnect.org/*":"https://connectore.org/:splat 301!","https://www.copperconnect.org/*":"https://connectore.org/:splat 301!"},"dynamicEntityTypeNames":["Person","Organisation"],"entities":{"OrganisationType":{},"HorizonType":{},"Economy":{},"FeedEntry":{},"Post":{},"Citation":{},"Objective":{},"ProfileType":{},"ExpertiseLevel":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
