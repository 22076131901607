import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Trend } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { ExplorerTabs, ExplorerTabsSpec, SiteFooterProvider, SliceLayout, useView } from '@shapeable/ui';

import { ExplorerThemesLayout } from './explorer-themes-layout';
import { ExplorerInnovationsLayout } from './explorer-innovations-layout';
import { ExplorerCommunityLayout } from './explorer-community-layout';
import { ExplorerMarketMapLayout } from './explorer-market-map-layout';
import { ExplorerLibraryLayout } from './explorer-library-layout';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { WITH_AUTHENTICATION_REQUIRED_OPTIONS } from '../../data';
import { classNames } from '@shapeable/utils';
import { SiteFooterLayout } from '@shapeable/ui';
import { AiUpperCaseIconGlyph, ConnectionsNavigatorIconGlyph, GridIconGlyph } from '@shapeable/icons';
import { ExplorerGptLayout } from './explorer-gpt-layout';
import { ExplorerHomeLayout } from './explorer-home-layout';
import { ExplorerView } from '../../types';
import { ExplorerConversationsLayout } from './explorer-conversations-layout';
const cls = classNames('explorer-index-layout');

// Move to UI package

// -------- Types -------->

export type ExplorerIndexLayoutProps = Classable & HasChildren & { 
  entity: Entity;
};

export const ExplorerIndexLayoutDefaultProps: Omit<ExplorerIndexLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
});

const TabsStyles = breakpoints({
  base: css`
    

  `,
});

const ViewChildStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

const InnovationsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ConversationsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ExplorerStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ThemesStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ViewStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: ${theme.COLOR('app-chrome')};
  `,
});

const FooterLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    View: styled.div`${ViewStyles}`,
      GptLayout: styled(ExplorerGptLayout)`${GptLayoutStyles}`,
      Explorer: styled(ExplorerHomeLayout).attrs(cls.attr('summary'))`${ExplorerStyles}`,
      Conversations: styled(ExplorerConversationsLayout).attrs(cls.attr('conversations'))`${ConversationsStyles}`,
      Innovations: styled(ExplorerInnovationsLayout).attrs(cls.attr('innovations'))`${InnovationsStyles}`,
      ValueChain: styled(ExplorerThemesLayout).attrs(cls.attr('value-chain'))`${ThemesStyles}`,
      Community: styled(ExplorerCommunityLayout).attrs(cls.attr('community'))`${ViewChildStyles}`,
      MarketMap: styled(ExplorerMarketMapLayout).attrs(cls.attr('market-map'))`${ViewChildStyles}`,
      Library: styled(ExplorerLibraryLayout).attrs(cls.attr('library'))`${ViewChildStyles}`,

      Footer: styled(SiteFooterLayout)`${FooterLayoutStyles}`,

};

export const ExplorerIndexLayoutComponent: Shapeable.FC<ExplorerIndexLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isActiveView } = useView<ExplorerView>('explorer', 'value-chain');

  return (
    <>
    {/* <SliceLayout boundary='none' >  */}
    <My.Container className={cls.name(className)}>
      <My.Tabs useStickyStyle defaultView='value-chain' />
      <My.View>
      {/* {
        isActiveView('ai') && 
        <My.GptLayout entity={entity} />
      } */}
      {
        isActiveView('value-chain') && 
        <My.Explorer entity={entity} />
      }
      {
        isActiveView('conversations') && 
        <My.Conversations entity={entity} />
      }
      {
        isActiveView('innovations') && 
        <My.Innovations />
      }
      {/* {
        isActiveView('value-chain') && 
        <My.ValueChain />
      } */}
      {/* {
        isActiveView('impact-strategies') &&
        <My.ImpactStrategies />
      } */}
      {
        isActiveView('community') &&
        <My.Community />
      }
      {
        isActiveView('stakeholder-map') &&
        <My.MarketMap />
      }
      {
        isActiveView('references') &&
        <My.Library />
      }

      </My.View>
    </My.Container>
    {/* </SliceLayout> */}
    </>
  )
};

ExplorerIndexLayoutComponent.defaultProps = ExplorerIndexLayoutDefaultProps;
ExplorerIndexLayoutComponent.cls = cls;

export const ExplorerIndexLayout = withAuthenticationRequired(ExplorerIndexLayoutComponent, WITH_AUTHENTICATION_REQUIRED_OPTIONS);