import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
// import { RadarChart } from '@shapeable/gesda-ui';
import { ExplorerTabs, SiteHeaderLayout, SiteHeaderProvider, useAuth } from '@shapeable/ui';
import { BackButtonEntityProvider, ComponentPropsProvider, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, PAGE_HOME, SliceLayoutBoundary } from '@shapeable/ui';
import { ExplorerProvider, PAGE_NAVIGATOR } from '../providers/explorer-provider';
import { Trend } from '@shapeable/copper-connect-types';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { TrendTabsLayout } from './trend-tabs-layout';
import { TrendViewLayout } from './trend-view-layout';
const cls = classNames('trend-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Trend;
};

export const TrendExplorerLayoutDefaultProps: Omit<TrendExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    .shp--entity-banner {
      h2.shp--entity-value__name {
        font-size: ${theme.FONT_SIZE(32)};
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Navigator: styled(ValueChainNavigator)`${RadarChartStyles}`,

      Tabs: styled(ExplorerTabs)`${TabsStyles}`,

    
};

export const TrendExplorerLayout: Shapeable.FC<TrendExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isShown } = useValueChainMap();
  const auth = useAuth();
  
  return (
    <ExplorerProvider>
    <BackButtonEntityProvider value={PAGE_NAVIGATOR}>
    <SiteHeaderProvider value={{ 
        variant: 'default',
        menuItemIsAvailable: (item) => !(item.slug === 'explore' && !auth.isAuthenticated),
      }}>
    <ComponentPropsProvider value={{
      EntityBanner: {
        maxHeight: 300,
        variant: 'overlay',
        customAspectRatio: { base: 16 / 9, desktop: 21 / 9 },
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }},
      },
      EntityCard: {
        variant: 'chromeless',
      },
    }}>
    <My.Container>
      <My.Header />
      <SliceLayoutBoundary boundary='none' >
      <My.Tabs useStickyStyle />
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        stickyTopOffset={117}

        //main
        tabs={
          <TrendTabsLayout entity={entity} />
        }
        content={
          <TrendViewLayout entity={entity} />
        }
        dataVizMain={
          <ValueChainNavigatorMain />
        }

        //aside
        dataViz={
          <My.Navigator />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </SiteHeaderProvider>
    </BackButtonEntityProvider>
    </ExplorerProvider>
  )
};

TrendExplorerLayout.defaultProps = TrendExplorerLayoutDefaultProps;
TrendExplorerLayout.cls = cls;